(function () {
    'use strict';

    angular.module('adminApp')
        .controller("NotificationRegistration", NotificationRegistrationController)
        .controller("AddEditNotificationRegistration", AddEditNotificationRegistrationController);

    NotificationRegistrationController.$inject = ["$scope", "$uibModal", "Notification", "aerosAdminApi"];

    function NotificationRegistrationController($scope, $uibModal, Notification, aerosAdminApi) {
        $scope.$watch("commonReady", function () {
            // if (!$scope.allow['editNotification']) return $scope.reject();

            init();
        });

        function init() {
            aerosAdminApi.getAllNotificationRegistrations()
                .then(function (result) {
                    $scope.notificationRegistrations = result.data;
                })
        }

        $scope.openAddEditNotificationRegistrationModal = function (data, index) {
            var modal = $uibModal.open({
                size: "lg",
                templateUrl: "/static/templates/admin/modals/addEditNotificationRegistration.html",
                controller: "AddEditNotificationRegistration",
                resolve: {
                    notificationRegistration: function () {
                        return (typeof data !== 'undefined') ? aerosAdminApi.getNotificationRegistrations(data.id).then(function (response) {
                            return response.data
                        }) : {}
                    }
                }
            });

            modal.result.then(function (data) {
                (typeof index !== 'undefined')
                    ?
                    $scope.notificationRegistrations[index] = data
                    :
                    $scope.notificationRegistrations.push(data);
            })
        };

        $scope.delete = function (notificationRegistration) {
            aerosAdminApi.deleteNotificationRegistrations(notificationRegistration.id)
                .then(function () {
                    var index = $scope.notificationRegistrations.indexOf(notificationRegistration);

                    $scope.notificationRegistrations.splice(index, 1);
                    Notification.success('Notification registration successfully deleted.');
                })
        }
    }

    AddEditNotificationRegistrationController.$inject = ["$scope", "aerosAdminApi", "Notification", "$uibModalInstance",
        "notificationRegistration"];

    function AddEditNotificationRegistrationController($scope, aerosAdminApi, Notification, $uibModalInstance,
                                                       notificationRegistration) {
        $scope.model = notificationRegistration;

        $scope.messageProviders = [
            {name: 'gcm', value: 'gcm'},
            {name: 'pushyme', value: 'pushyme'}
        ];
        $scope.platforms = [
            {name: 'ios', value: 'ios'},
            {name: 'android', value: 'android'},
            {name: 'ubase1', value: 'ubase1'}
        ];

        $scope.save = function () {
            $scope.model.id ? update() : save();
        };

        function save() {
            aerosAdminApi.addNotificationRegistrations($scope.model)
                .then(function (response) {
                    $uibModalInstance.close(response.data);
                    Notification.success('Notification registration successfully added.');
                })
                .catch(function (error) {
                    Notification.error('Notification registration failed. ' + error.data.message);
                })
        }

        function update() {
            aerosAdminApi.updateNotificationRegistrations($scope.model)
                .then(function (response) {
                    $uibModalInstance.close(response.data);
                    Notification.success('Notification registration successfully added.');
                })
                .catch(function (error) {
                    Notification.error('Notification registration update failed. ' + error.data.message);
                })
        }
    }
}());
